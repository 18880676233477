import { faUser } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { noCase } from '@indieocean/utils'
import Image from 'next/image'
import React, { CSSProperties } from 'react'
import { Config } from '../../Config'

type Size = 55 | 50 | 40 | 30
export const ProfileImage = React.memo(
  ({
    store,
    size,
    className = '',
    style,
  }: {
    store: {data: {profileImage?: string | null}; user: {userId: string}}
    size: Size
    className?: string
    style?: CSSProperties
  }) => {
    return store.data.profileImage ? (
      <div
        className={`rounded-full overflow-hidden ${sizeStr(size)} ${className}`}
        style={style}
      >
        <Image
          src={Config.client.google.storage.buckets.data
            .user(store.user.userId)
            .profileImage(store.data.profileImage)}
          width={size}
          height={size}
        />
      </div>
    ) : (
      <div
        className={`${sizeStr(
          size
        )} sectionLight border-2 border-inputBorder rounded-full
    flex justify-center items-center text-lg  ${className}`}
        style={style}
      >
        <FontAwesomeIcon icon={faUser} />
      </div>
    )
  }
)

const sizeStr = (size: Size) => {
  switch (size) {
    case 55:
      return 'h-[55px] w-[55px]'
    case 50:
      return 'h-[50px] w-[50px]'
    case 40:
      return 'h-[40px] w-[40px]'
    case 30:
      return 'h-[30px] w-[30px]'
    default:
      noCase(size)
  }
}
