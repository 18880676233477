import _ from 'lodash'
import React, { ReactNode, useEffect, useState } from 'react'
import { Config } from '../../Config'
import { createContext } from '../Utils/CreateContext'
import { useUser } from './WithUser'

const [IntercomUnreadCountContext, useIntercomUnreadCount] =
  createContext<number>('IntercomUnreadCountContext')

export {useIntercomUnreadCount}

type _IntercomSetting =
  | {
      email: string
      user_id: string
      user_hash: string
      name?: string
    }
  | Record<string, never>

let _currIntercomUserInfo: _IntercomSetting | null = null
let _unreadCount = 0

export const WithIntercom = React.memo(({children}: {children: ReactNode}) => {
  const user = useUser()
  const [unreadCount, setUnreadCount] = useState(_unreadCount)
  useEffect(() => {
    const intercomUserInfo: _IntercomSetting = user
      ? {
          email: user.email,
          user_id: user.userId,
          user_hash: user.hashForIntercom,
          name: user.store?.name,
        }
      : {}
    const Intercom = (window as any).Intercom
    // From examining the intercom script in the header, it looks like it
    // queues up the commands before load, so we don't have to worry about
    // waiting for init.
    if (!_.isEqual(_currIntercomUserInfo, intercomUserInfo)) {
      Intercom('shutdown')
      Intercom('boot', {
        app_id: Config.client.connections.intercom.appId,
        hide_default_launcher: true,
        custom_launcher_selector: '.intercomLauncher',
        ...intercomUserInfo,
      })
      // The default launcher is used effectively as a close button and is show
      // only when the messenger is open (there is no other way to close the
      // messenger from inside it, so we need to do this).
      Intercom('onShow', () =>
        Intercom('update', {hide_default_launcher: false})
      )
      Intercom('onHide', () =>
        Intercom('update', {hide_default_launcher: true})
      )
      _currIntercomUserInfo = intercomUserInfo
    } else {
      // Call update even if user info has not changed, because this is how it
      // logs views and pings for updates.
      Intercom('update')
    }
    Intercom('onUnreadCountChange', (x: number) => {
      _unreadCount = x
      setUnreadCount(x)
    })
    // User dependency won't change, but too tedious to unpack and assertConst.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <IntercomUnreadCountContext.Provider value={unreadCount}>
      {children}
    </IntercomUnreadCountContext.Provider>
  )
})
