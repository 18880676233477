import { API } from '@indieocean/apidef'
import { useCallback } from 'react'
import { useURLParam } from '../../../Utils/UseURLParam'


export const useAppPathFn = () => {
  const forSlug = useURLParam('for')
  return useCallback(
    (path: API.Path) => {
      let dummyHost = 'https://dummy.com'
      const url = new URL(`${dummyHost}${path.toString()}`)
      if (forSlug) url.searchParams.set('for', forSlug)
      // This preserves fragments.
      return url.href.substr(dummyHost.length)
    },
    [forSlug]
  )
}
