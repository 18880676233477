import React from 'react'

export const Badge = React.memo(({value}: {value: number}) => {
  return (
    <div
      className="absolute text-2xs-tight right-0 top-0 pt-0.5 pb-0.5 pl-1.5 pr-1.5 bg-badgeBG  text-white
    flex justify-center items-center rounded-full"
    >
      <h1 className="">{value}</h1>
    </div>
  )
})
