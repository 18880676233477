import { noCase } from '@indieocean/utils'
import { Snackbar } from '@material-ui/core'
import React, { ReactNode, useCallback, useState } from 'react'
import { createContext } from '../Utils/CreateContext'
import { useAssertConst } from '../Utils/UseAssertConst'
import { GQLFetchError } from './WithRelay'

const _messageFromGQLError = (error: GQLFetchError) => {
  switch (error.type) {
    case 'NetworkError':
      return 'Cannot connect. Are you online?'
    case 'ServerError':
      return 'Something went wrong on the server.'
    // Server errors.
    case 'GRAPHQL_PARSE_FAILED':
    case 'GRAPHQL_VALIDATION_FAILED':
    case 'PERSISTED_QUERY_NOT_FOUND':
    case 'PERSISTED_QUERY_NOT_SUPPORTED':
    case 'BAD_USER_INPUT':
      return 'Something went wrong.'
    case 'UNAUTHENTICATED':
      return 'You have been logged out.'
    case 'FORBIDDEN':
      return 'Unauthorized.'
    case 'INTERNAL_SERVER_ERROR':
      return 'Something went wrong on the server.'
    // Custom server errors.
    case 'STATE_MISMATCH':
      return 'Data is stale. Reload and try again.'
    // Client error.
    case 'NotFound':
    case 'NoStoreToAdminister':
    case 'MaintainanceMode':
    case 'Custom':
      throw new Error() // These should be caught by error boundaries, not here.
    default:
      noCase(error.type)
  }
}

type Value = {
  errorToast: (message: string | GQLFetchError, key?: string) => void
  successToast: (message: string, key?: string) => void
  infoToast: (message: string, key?: string) => void
}

const [ValueContext, useGlobalToasts] = createContext<Value>('GlobalToasts')
export { useGlobalToasts }

export const WithGlobalToasts = React.memo(
  ({children}: {children: ReactNode}) => {
    const [[messages], setMessages] = useState<
      [Map<string, {type: 'error' | 'success' | 'normal'; message: string}>]
    >([new Map()])

    const _newToast = useCallback(
      (
        message: {type: 'error' | 'success' | 'normal'; message: string},
        key = 'Default'
      ) => {
        setMessages(([messages]) => {
          messages.set(key, message)
          return [messages]
        })
      },
      []
    )

    const errorToast = useCallback(
      (message: string | GQLFetchError, key?: string) => {
        const messageStr =
          typeof message === 'string' ? message : _messageFromGQLError(message)
        _newToast({type: 'error', message: messageStr}, key)
      },
      [_newToast]
    )
    useAssertConst([_newToast])

    const successToast = useCallback(
      (message: string, key?: string) =>
        _newToast({type: 'success', message}, key),
      [_newToast]
    )
    useAssertConst([_newToast])

    const infoToast = useCallback(
      (message: string, key?: string) =>
        _newToast({type: 'normal', message}, key),
      [_newToast]
    )
    useAssertConst([_newToast])

    const removeMessage = useCallback((key: string) => {
      setMessages(([messages]) => {
        messages.delete(key)
        return [messages]
      })
    }, [])

    return (
      <ValueContext.Provider value={{errorToast, infoToast, successToast}}>
        {children}

        {[...messages].map(([key, {type, message}]) => (
          <Snackbar
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            open={true}
            onClose={() => removeMessage(key)}
            key={key}
            autoHideDuration={3000}
          >
            <div
              className={`shadow-lg p-3 pl-4 pr-4 rounded-md ${
                type === 'error'
                  ? 'text-errorBlockFG bg-errorBlockBG'
                  : type === 'success'
                  ? 'text-successBlockFG bg-successBlockBG'
                  : type === 'normal'
                  ? ' text-sectionDarkFG bg-sectionDarkBG'
                  : noCase(type)
              }`}
            >
              {message}
            </div>
          </Snackbar>
        ))}
      </ValueContext.Provider>
    )
  }
)
