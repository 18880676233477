import { fGet } from '@indieocean/utils'
import React, { ReactElement } from 'react'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { useURLParam } from '../../Utils/UseURLParam'
import { createContext } from '../Utils/CreateContext'
import { useFirebaseUser } from './WithFirebaseUser'
import { GQLFetchError } from './WithRelay'
import { WithTargetUserQuery } from './__generated__/WithTargetUserQuery.graphql'

const [Context, useTargetUser] = createContext<string | null>('TargetUser')
export { useTargetUser }
export const useFTargetUser = () => fGet(useTargetUser())
const query = graphql`
  query WithTargetUserQuery($slug: String!) {
    store(slug: $slug) {
      user {
        userId
      }
    }
  }
`

export const WithTargetUser = React.memo(
  ({children}: {children: ReactElement}) => {
    const firebaseUser = useFirebaseUser()
    const forSlug = useURLParam('for')
    if (!forSlug) {
      return (
        <Context.Provider value={firebaseUser?.uid ?? null}>
          {children}
        </Context.Provider>
      )
    } else {
      return <_WithTargetUser forSlug={forSlug}>{children}</_WithTargetUser>
    }
  }
)

const _WithTargetUser = React.memo(
  ({forSlug, children}: {forSlug: string; children: ReactElement}) => {
    const firebaseUser = useFirebaseUser()

    if (!firebaseUser)
      throw new GQLFetchError(
        'Custom',
        'You have to be signed in to have a target user.'
      )
    const {store} = useLazyLoadQuery<WithTargetUserQuery>(query, {
      slug: forSlug.toLowerCase(),
    })
    const userId = store?.user.userId ?? null
    if (!userId)
      throw new GQLFetchError('Custom', 'Could not find target slug.')
    return <Context.Provider value={userId}>{children}</Context.Provider>
  }
)
