/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WithUser_buyingDetails = {
    readonly shippingAddress: {
        readonly firstName: string;
        readonly lastName: string;
        readonly addressLine1: string;
        readonly addressLine2: string | null;
        readonly city: string;
        readonly state: string;
        readonly zip: string;
        readonly countryCode: API$CountryCode;
    } | null;
    readonly card: {
        readonly cardId: string;
        readonly brand: string;
        readonly last4: string;
    } | null;
    readonly " $refType": "WithUser_buyingDetails";
};
export type WithUser_buyingDetails$data = WithUser_buyingDetails;
export type WithUser_buyingDetails$key = {
    readonly " $data"?: WithUser_buyingDetails$data;
    readonly " $fragmentRefs": FragmentRefs<"WithUser_buyingDetails">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WithUser_buyingDetails",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "shippingAddress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressLine1",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressLine2",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "zip",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "countryCode",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentCardDetails",
      "kind": "LinkedField",
      "name": "card",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cardId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "brand",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last4",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BuyingDetails",
  "abstractKey": null
};
(node as any).hash = 'ffda217c928b026ffc3339e081d19761';
export default node;
