import { noCase } from '@indieocean/utils'
import React from 'react'
import { useDepList } from './UseDepList'

export function useStrictMemo<T>(value: T | (() => T), deps: any[]): T {
  const state = useDepList(deps)

  const ref = React.useRef<T>()
  switch (state) {
    case 'new':
    case 'changed':
      ref.current =
        typeof value === 'function' ? (value as () => T)() : (value as T)
      break
    case 'noChange':
      break
    default:
      noCase(state)
  }
  if (ref.current === undefined) throw new Error()
  return ref.current
}
