import {createTheme, ThemeProvider} from '@material-ui/core'
import type {AppProps} from 'next/app'
import Head from 'next/head'
import {useRouter} from 'next/router'
import React, {useEffect, useState} from 'react'
import {GlobalErrorBoundary} from '../src/Components/Common/GlobalErrorBoundary'
import {GlobalSuspense} from '../src/Components/Common/Tools/GlobalSuspense'
import {getNotificationSound} from '../src/Components/Common/Tools/NotificationSound'
import {WithAlgoliaIndices} from '../src/Components/Common/WithAlgoliaIndex'
import {WithFirebaseAuth} from '../src/Components/Common/WithFirebaseUser'
import {WithGlobalToasts} from '../src/Components/Common/WithGlobalToasts'
import {WithIntercom} from '../src/Components/Common/WithIntercom'
import {WithRelay} from '../src/Components/Common/WithRelay'
import {WithTargetUser} from '../src/Components/Common/WithTargetUser'
import {WithUser} from '../src/Components/Common/WithUser'
import {Config} from '../src/Config'
import getStripe from '../src/Remote/GetStripe'
import '../styles/globals.css'

if (process.browser) {
  require('smoothscroll-polyfill').polyfill()
}

// Preload stripe.
void getStripe()
const theme = createTheme({
  typography: {fontFamily: 'Montserrat, sans-serif', body1: {}},
})

function MyApp({Component, pageProps}: AppProps) {
  const router = useRouter()
  // Hard stop on SSG because suspense is not (yet) supported. You need both
  // render and router.isReady to handle case where there are query params,
  // and when there are no query params.
  const [render, setRender] = useState(false)
  useEffect(() => setRender(true), [])
  if (!(render && router.isReady)) return <div></div>

  // Preload notification sound.
  getNotificationSound()

  // From examining the intercom script in the header, it looks like it
  // queues up the commands before load, so we don't have to worry about
  // waiting for init.
  const Intercom = (window as any).Intercom
  if (!Intercom.booted) {
    Intercom('boot', {
      app_id: Config.client.connections.intercom.appId,
      hide_default_launcher: true,
    })
  }

  return (
    <div className="app ">
      <Head>
        <title>IndieOcean</title>
      </Head>
      <ThemeProvider theme={theme}>
        {/* Toasts is above error boundary because it is used on authError() */}
        <WithGlobalToasts>
          {/* Auth is above error boundary because it calls authError() */}
          {/* Auth is above relay because it needs id token */}
          <WithFirebaseAuth>
            {/* Error boundary is above relay because it displays network error. */}
            <GlobalErrorBoundary>
              <GlobalSuspense>
                <WithRelay>
                  <WithTargetUser>
                    <WithUser>
                      <WithIntercom>
                        <WithAlgoliaIndices>
                          <Component {...pageProps} />
                        </WithAlgoliaIndices>
                      </WithIntercom>
                    </WithUser>
                  </WithTargetUser>
                </WithRelay>
              </GlobalSuspense>
            </GlobalErrorBoundary>
          </WithFirebaseAuth>
        </WithGlobalToasts>
      </ThemeProvider>
    </div>
  )
}

export default MyApp
