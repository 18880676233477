/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WithUser_store = {
    readonly id: string;
    readonly user: {
        readonly id: string;
        readonly userId: string;
    };
    readonly name: string;
    readonly slug: string;
    readonly data: {
        readonly profileImage: string | null;
        readonly introduction: string | null;
        readonly links: {
            readonly twitter: {
                readonly username: string;
            } | null;
        };
        readonly amazonAssociateIds: ReadonlyArray<{
            readonly country: API$CountryCode;
            readonly associateId: string;
        }>;
        readonly follows: {
            readonly id: string;
            readonly aggregate: {
                readonly id: string;
                readonly followsWhereIsSrcCount: number;
                readonly followsWhereIsTargetCount: number;
            };
            readonly relativeToOther: {
                readonly isOtherFollowsSrc: boolean;
                readonly isOtherFollowsTarget: boolean;
                readonly selfFollowTargetsInOtherFollowTargets: number;
                readonly selfFollowSrcsInOtherFollowTargets: number;
            } | null;
        };
        readonly payoutPercent: number;
        readonly stripe: {
            readonly __typename: "StripeCreated";
            readonly payoutDelayDays: number;
            readonly payoutDayOfWeek: API$DayOfTheWeek;
            readonly payoutsEnabled: boolean;
            readonly chargesEnabled: boolean;
            readonly requirements: API$StripeRequirements;
            readonly balance: number;
        } | {
            readonly __typename: "StripeNotCreated";
            readonly reason: API$StripeNotCreatedReason;
        } | {
            /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        };
        readonly basicStats: {
            readonly booksSold: number;
            readonly booksRefunded: number;
        };
    } | null;
    readonly " $refType": "WithUser_store";
};
export type WithUser_store$data = WithUser_store;
export type WithUser_store$key = {
    readonly " $data"?: WithUser_store$data;
    readonly " $fragmentRefs": FragmentRefs<"WithUser_store">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "WithUser_store",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StoreData",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profileImage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "introduction",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StoreLinks",
          "kind": "LinkedField",
          "name": "links",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StoreLinksTwitter",
              "kind": "LinkedField",
              "name": "twitter",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "username",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AmazonAssociateId",
          "kind": "LinkedField",
          "name": "amazonAssociateIds",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "country",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "associateId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StoreFollows",
          "kind": "LinkedField",
          "name": "follows",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "StoreFollowsAggregate",
              "kind": "LinkedField",
              "name": "aggregate",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "followsWhereIsSrcCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "followsWhereIsTargetCount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "userId",
                  "variableName": "userId"
                }
              ],
              "concreteType": "StoreFollowsRelativeToOther",
              "kind": "LinkedField",
              "name": "relativeToOther",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isOtherFollowsSrc",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isOtherFollowsTarget",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "selfFollowTargetsInOtherFollowTargets",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "selfFollowSrcsInOtherFollowTargets",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payoutPercent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "stripe",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "payoutDelayDays",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "payoutDayOfWeek",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "payoutsEnabled",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "chargesEnabled",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "requirements",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "balance",
                  "storageKey": null
                }
              ],
              "type": "StripeCreated",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "reason",
                  "storageKey": null
                }
              ],
              "type": "StripeNotCreated",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StoreBasicStats",
          "kind": "LinkedField",
          "name": "basicStats",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "booksSold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "booksRefunded",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Store",
  "abstractKey": null
};
})();
(node as any).hash = 'fee85b756b973c362c9b087e424fb741';
export default node;
