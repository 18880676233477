import React, { ReactNode, Suspense } from 'react'
import { AppPage } from '../Page/AppPage'
import { CenteredCircleProgress } from './CenteredCircleProgress'

export const GlobalSuspense = React.memo(
  ({children}: {children: ReactNode}) => {
    return (
      <Suspense
        fallback={
          <AppPage
            title="Loading IndieOcean"
            header={null}
            nav={null}
            showGoogleSignInPrompt={false}
          >
            <CenteredCircleProgress />
          </AppPage>
        }
      >
        {children}
      </Suspense>
    )
  }
)
