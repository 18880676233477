import { fGet } from '@indieocean/utils'
import React, { useEffect, useMemo } from 'react'
import { useRelayEnvironment } from 'react-relay'
import { commitLocalUpdate } from 'relay-runtime'
import { BasicStore, BasicStoreLive } from '../../Common/Page/WithBasicStoreLive'
import { useAssertConst } from '../../Utils/UseAssertConst'

export function useUpdateCacheForStore(store: BasicStore | null) {
  const env = useRelayEnvironment()
  const storeInfo = useMemo(
    () =>
      store?.slug && store?.id ? {slug: store.slug, gqlID: store.id} : null,
    [store?.slug, store?.id]
  )
  useEffect(() => {
    if (!storeInfo) return
    commitLocalUpdate(env, cache => {
      const root = cache.getRoot()
      const storeRecord = fGet(cache.get(storeInfo.gqlID))
      root.setLinkedRecord(storeRecord, 'store', {slug: storeInfo.slug})
    })
  }, [env, storeInfo])
  useAssertConst([env])
}

export const UpdateCacheForStore = React.memo(
  ({store}: {store: BasicStoreLive}) => {
    useUpdateCacheForStore(store)
    return <></>
  }
)
