import {noCase} from '@indieocean/utils'
import * as Sentry from '@sentry/nextjs'
import {useRouter} from 'next/router'
import React, {ReactNode, useEffect, useState} from 'react'
import {Config} from '../../Config'
import {firebaseAuth} from '../../Remote/Firebase'
import {createContext} from '../Utils/CreateContext'
import {useAssertConst} from '../Utils/UseAssertConst'
import {useStrictMemo} from '../Utils/UseStrictMemo'
import {AppPage} from './Page/AppPage'
import {ErrorBoundary} from './Tools/ErrorBoundary'
import {useGlobalToasts} from './WithGlobalToasts'
import {GQLFetchError} from './WithRelay'

type Value = {setGlobalError: (error: Error) => void}
const [Context, useSetGlobalError] = createContext<Value>('GlobalErrorCallback')
export {useSetGlobalError}

export const GlobalErrorBoundary = React.memo(
  ({children}: {children: ReactNode}) => {
    const [error, setGlobalError] = useState<Error | null>(null)
    if (error) return <_ErrorFallback error={error} />
    return (
      <Context.Provider value={{setGlobalError}}>
        <ErrorBoundary fallback={error => <_ErrorFallback error={error} />}>
          {children}
        </ErrorBoundary>
      </Context.Provider>
    )
  }
)
GlobalErrorBoundary.displayName = 'GlobalErrorBoundary'

export const _ErrorFallback = React.memo(({error}: {error: Error}) => {
  !Config.client.production && console.dir(error)
  const {errorToast} = useGlobalToasts()
  const isAuthError =
    error instanceof GQLFetchError && error.type === 'UNAUTHENTICATED'

  useEffect(() => {
    if (!isAuthError) return
    errorToast('You have been logged out.')
    void firebaseAuth.signOut()
  }, [errorToast, isAuthError])
  useAssertConst([errorToast, isAuthError])

  const router = useStrictMemo(useRouter(), [])
  useEffect(() => {
    Sentry.captureException(error)
    router.events.on('routeChangeComplete', () => window.location.reload())
  }, [error, router])
  useAssertConst([router, error])

  if (isAuthError) return null
  return (
    <AppPage
      title="Error on IndieOcean"
      header={{type: 'logo', size: 'center'}}
      nav={null}
      showGoogleSignInPrompt={false}
    >
      <div className="body-grid3">
        <div className="body-grid-content flex flex-col justify-center items-center text-xl">
          <span>{_message(error)}</span>
        </div>
      </div>
    </AppPage>
  )
})
_ErrorFallback.displayName = '_ErrorFallback'

const _message = (error: Error) => {
  if (!(error instanceof GQLFetchError)) return "I'm sorry, I crashed."

  switch (error.type) {
    case 'NetworkError':
      return 'Cannot connect. Are you online?'
    case 'ServerError':
      return 'Something went wrong on the server.'
    case 'MaintainanceMode':
      return 'We are updating the server. We will be back shortly!'
    // Server errors.
    case 'GRAPHQL_PARSE_FAILED':
    case 'GRAPHQL_VALIDATION_FAILED':
    case 'PERSISTED_QUERY_NOT_FOUND':
    case 'PERSISTED_QUERY_NOT_SUPPORTED':
    case 'BAD_USER_INPUT':
      return "I'm sorry, I crashed :("
    case 'UNAUTHENTICATED':
      throw new Error() // This should be caught and by the time we get here.
    case 'FORBIDDEN':
      return 'Unauthorized. You have no power here!'
    case 'INTERNAL_SERVER_ERROR':
      return 'Something went wrong on the server.'
    // Custom Server errors.
    case 'STATE_MISMATCH':
      return 'Data is stale. Please reload and try again.'
    // Client error.
    case 'NotFound':
      return '404 - There is nothing here.'
    case 'NoStoreToAdminister':
      return 'Trying to administer a non-existing store.'
    case 'Custom':
      return error.message
    default:
      noCase(error.type)
  }
}
