/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type WithUserQueryVariables = {
    userId: string;
};
export type WithUserQueryResponse = {
    readonly user: {
        readonly id: string;
        readonly userId: string;
        readonly hashForIntercom: string;
        readonly email: string;
        readonly earlyAccess: boolean;
        readonly unreadNotificationsCount: number;
        readonly buyingDetails: {
            readonly shippingAddress: {
                readonly firstName: string;
                readonly lastName: string;
                readonly addressLine1: string;
                readonly addressLine2: string | null;
                readonly city: string;
                readonly state: string;
                readonly zip: string;
                readonly countryCode: API$CountryCode;
            } | null;
            readonly card: {
                readonly cardId: string;
                readonly brand: string;
                readonly last4: string;
            } | null;
        };
        readonly store: {
            readonly id: string;
            readonly user: {
                readonly id: string;
                readonly userId: string;
            };
            readonly name: string;
            readonly slug: string;
            readonly data: {
                readonly profileImage: string | null;
                readonly introduction: string | null;
                readonly links: {
                    readonly twitter: {
                        readonly username: string;
                    } | null;
                };
                readonly amazonAssociateIds: ReadonlyArray<{
                    readonly country: API$CountryCode;
                    readonly associateId: string;
                }>;
                readonly follows: {
                    readonly id: string;
                    readonly aggregate: {
                        readonly id: string;
                        readonly followsWhereIsSrcCount: number;
                        readonly followsWhereIsTargetCount: number;
                    };
                    readonly relativeToOther: {
                        readonly isOtherFollowsSrc: boolean;
                        readonly isOtherFollowsTarget: boolean;
                        readonly selfFollowTargetsInOtherFollowTargets: number;
                        readonly selfFollowSrcsInOtherFollowTargets: number;
                    } | null;
                };
                readonly payoutPercent: number;
                readonly stripe: {
                    readonly __typename: "StripeCreated";
                    readonly payoutDelayDays: number;
                    readonly payoutDayOfWeek: API$DayOfTheWeek;
                    readonly payoutsEnabled: boolean;
                    readonly chargesEnabled: boolean;
                    readonly requirements: API$StripeRequirements;
                    readonly balance: number;
                } | {
                    readonly __typename: "StripeNotCreated";
                    readonly reason: API$StripeNotCreatedReason;
                } | {
                    /*This will never be '%other', but we need some
                    value in case none of the concrete values match.*/
                    readonly __typename: "%other";
                };
                readonly basicStats: {
                    readonly booksSold: number;
                    readonly booksRefunded: number;
                };
            } | null;
        } | null;
        readonly settings: {
            readonly email: {
                readonly store: {
                    readonly admin: {
                        readonly bookApproved: boolean;
                        readonly bookRejected: boolean;
                        readonly bookSold: boolean;
                    };
                    readonly social: {
                        readonly likes: boolean;
                        readonly comments: boolean;
                        readonly follows: boolean;
                    };
                };
            };
        };
    };
    readonly localizationCountry: API$CountryCode | null;
};
export type WithUserQuery = {
    readonly response: WithUserQueryResponse;
    readonly variables: WithUserQueryVariables;
};



/*
query WithUserQuery(
  $userId: String!
) {
  user(userId: $userId) {
    id
    userId
    hashForIntercom
    email
    earlyAccess
    unreadNotificationsCount
    buyingDetails {
      shippingAddress {
        firstName
        lastName
        addressLine1
        addressLine2
        city
        state
        zip
        countryCode
      }
      card {
        cardId
        brand
        last4
      }
    }
    store {
      id
      user {
        id
        userId
      }
      name
      slug
      data {
        profileImage
        introduction
        links {
          twitter {
            username
          }
        }
        amazonAssociateIds {
          country
          associateId
        }
        follows {
          id
          aggregate {
            id
            followsWhereIsSrcCount
            followsWhereIsTargetCount
          }
          relativeToOther(userId: $userId) {
            isOtherFollowsSrc
            isOtherFollowsTarget
            selfFollowTargetsInOtherFollowTargets
            selfFollowSrcsInOtherFollowTargets
          }
        }
        payoutPercent
        stripe {
          __typename
          __isStripe: __typename
          ... on StripeCreated {
            payoutDelayDays
            payoutDayOfWeek
            payoutsEnabled
            chargesEnabled
            requirements
            balance
          }
          ... on StripeNotCreated {
            reason
          }
        }
        basicStats {
          booksSold
          booksRefunded
        }
      }
    }
    settings {
      email {
        store {
          admin {
            bookApproved
            bookRejected
            bookSold
          }
          social {
            likes
            comments
            follows
          }
        }
      }
    }
  }
  localizationCountry
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hashForIntercom",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "earlyAccess",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unreadNotificationsCount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "BuyingDetails",
  "kind": "LinkedField",
  "name": "buyingDetails",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "shippingAddress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressLine1",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressLine2",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "zip",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "countryCode",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentCardDetails",
      "kind": "LinkedField",
      "name": "card",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cardId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "brand",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last4",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileImage",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "introduction",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "StoreLinks",
  "kind": "LinkedField",
  "name": "links",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StoreLinksTwitter",
      "kind": "LinkedField",
      "name": "twitter",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "AmazonAssociateId",
  "kind": "LinkedField",
  "name": "amazonAssociateIds",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "associateId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "StoreFollows",
  "kind": "LinkedField",
  "name": "follows",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "StoreFollowsAggregate",
      "kind": "LinkedField",
      "name": "aggregate",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "followsWhereIsSrcCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "followsWhereIsTargetCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "StoreFollowsRelativeToOther",
      "kind": "LinkedField",
      "name": "relativeToOther",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isOtherFollowsSrc",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isOtherFollowsTarget",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "selfFollowTargetsInOtherFollowTargets",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "selfFollowSrcsInOtherFollowTargets",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payoutPercent",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v19 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payoutDelayDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payoutDayOfWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payoutsEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "chargesEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requirements",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "balance",
      "storageKey": null
    }
  ],
  "type": "StripeCreated",
  "abstractKey": null
},
v20 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    }
  ],
  "type": "StripeNotCreated",
  "abstractKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "StoreBasicStats",
  "kind": "LinkedField",
  "name": "basicStats",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "booksSold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "booksRefunded",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "Settings",
  "kind": "LinkedField",
  "name": "settings",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EmailSettings",
      "kind": "LinkedField",
      "name": "email",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EmailSettingsStore",
          "kind": "LinkedField",
          "name": "store",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EmailSettingsStoreAdmin",
              "kind": "LinkedField",
              "name": "admin",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "bookApproved",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "bookRejected",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "bookSold",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "EmailpreferencesStoreSocial",
              "kind": "LinkedField",
              "name": "social",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "likes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "comments",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "follows",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizationCountry",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WithUserQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Store",
            "kind": "LinkedField",
            "name": "store",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreData",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "stripe",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v21/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v22/*: any*/)
        ],
        "storageKey": null
      },
      (v23/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WithUserQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Store",
            "kind": "LinkedField",
            "name": "store",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreData",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "stripe",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isStripe"
                      },
                      (v19/*: any*/),
                      (v20/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v21/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v22/*: any*/)
        ],
        "storageKey": null
      },
      (v23/*: any*/)
    ]
  },
  "params": {
    "cacheID": "ce86a46ad7459928e1681aface67e64a",
    "id": null,
    "metadata": {},
    "name": "WithUserQuery",
    "operationKind": "query",
    "text": "query WithUserQuery(\n  $userId: String!\n) {\n  user(userId: $userId) {\n    id\n    userId\n    hashForIntercom\n    email\n    earlyAccess\n    unreadNotificationsCount\n    buyingDetails {\n      shippingAddress {\n        firstName\n        lastName\n        addressLine1\n        addressLine2\n        city\n        state\n        zip\n        countryCode\n      }\n      card {\n        cardId\n        brand\n        last4\n      }\n    }\n    store {\n      id\n      user {\n        id\n        userId\n      }\n      name\n      slug\n      data {\n        profileImage\n        introduction\n        links {\n          twitter {\n            username\n          }\n        }\n        amazonAssociateIds {\n          country\n          associateId\n        }\n        follows {\n          id\n          aggregate {\n            id\n            followsWhereIsSrcCount\n            followsWhereIsTargetCount\n          }\n          relativeToOther(userId: $userId) {\n            isOtherFollowsSrc\n            isOtherFollowsTarget\n            selfFollowTargetsInOtherFollowTargets\n            selfFollowSrcsInOtherFollowTargets\n          }\n        }\n        payoutPercent\n        stripe {\n          __typename\n          __isStripe: __typename\n          ... on StripeCreated {\n            payoutDelayDays\n            payoutDayOfWeek\n            payoutsEnabled\n            chargesEnabled\n            requirements\n            balance\n          }\n          ... on StripeNotCreated {\n            reason\n          }\n        }\n        basicStats {\n          booksSold\n          booksRefunded\n        }\n      }\n    }\n    settings {\n      email {\n        store {\n          admin {\n            bookApproved\n            bookRejected\n            bookSold\n          }\n          social {\n            likes\n            comments\n            follows\n          }\n        }\n      }\n    }\n  }\n  localizationCountry\n}\n"
  }
};
})();
(node as any).hash = '6210f4b1a7b906a8a93df11fcce20923';
export default node;
