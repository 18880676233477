import {assert, noCase} from '@indieocean/utils'
import {signInWithCustomToken} from 'firebase/auth'
import Head from 'next/head'
import React, {ReactNode, Suspense, useEffect} from 'react'
import {graphql} from 'relay-runtime'
import {Config} from '../../../Config'
import {GQL} from '../../../Generated/GQL/TypesFromSDL'
import {firebaseAuth} from '../../../Remote/Firebase'
import {useAssertConst} from '../../Utils/UseAssertConst'
import {CenteredCircleProgress} from '../Tools/CenteredCircleProgress'
import {useGQLMutation} from '../Tools/UseGQLMutation'
import {useFirebaseUser} from '../WithFirebaseUser'
import {useGlobalToasts} from '../WithGlobalToasts'
import {Header, HeaderSpec} from './Header'
import {Nav, NavProps} from './Nav/Nav'
import {AppPageVerifyIdTokenMutation} from './__generated__/AppPageVerifyIdTokenMutation.graphql'

export const AppPage = React.memo(
  ({
    children,
    title,
    header,
    nav,
    showGoogleSignInPrompt,
  }: {
    children: ReactNode
    title: string
    header: HeaderSpec
    nav: NavProps | null
    showGoogleSignInPrompt: boolean | 'alreadySignedIn'
  }) => {
    useOneTapGoogleSignIn(showGoogleSignInPrompt)
    return (
      <div className="page">
        <Head>
          <title>{title}</title>
        </Head>
        <Header spec={header} />
        <div className="z-0 min-h-0" style={{gridArea: 'body'}}>
          <Suspense fallback={<CenteredCircleProgress />}>{children}</Suspense>
        </div>
        {nav && <Nav {...nav} />}
      </div>
    )
  }
)

const verifyIdMutation = graphql`
  mutation AppPageVerifyIdTokenMutation(
    $args: UserLoginGoogleVerifyIdTokenArgs!
  ) {
    userLoginGoogleVerifyIdToken(args: $args) {
      __typename
      ... on UserLoginGoogleVerifyIdTokenSuccess {
        firebaseToken
      }
      ... on ErrorWithMessage {
        message
      }
    }
  }
`

function useOneTapGoogleSignIn(enable: boolean | 'alreadySignedIn') {
  const signedIn = useFirebaseUser() !== null
  useAssertConst([enable])
  if (enable === 'alreadySignedIn') assert(signedIn)
  if (!enable || signedIn) return
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useOneTapGoogleSignInImpl()
}

function useOneTapGoogleSignInImpl() {
  const {errorToast} = useGlobalToasts()
  const [commit] = useGQLMutation<
    AppPageVerifyIdTokenMutation,
    GQL.AppPageVerifyIdTokenMutation
  >(verifyIdMutation, 'soft')

  useEffect(() => {
    const client = (window as any).google.accounts.id
    if (!client) return
    client.initialize({
      client_id: Config.client.connections.googleOAuth.clientId,
      cancel_on_tap_outside: false,
      context: 'use',
      callback: (e: any) => {
        const idToken: string = e.credential
        commit({
          variables: {args: {idToken}},
          onCompleted: ({userLoginGoogleVerifyIdToken: result}) => {
            switch (result.__typename) {
              case 'ErrorWithMessage':
                errorToast(result.message)
                break
              case 'UserLoginGoogleVerifyIdTokenSuccess':
                void signInWithCustomToken(
                  firebaseAuth,
                  result.firebaseToken
                ).catch(e => {
                  errorToast('Something went wrong.')
                })
                break
              default:
                noCase(result)
            }
          },
        })
      },
    })
    client.prompt()
  }, [commit, errorToast])
  useAssertConst([commit, errorToast])
}
