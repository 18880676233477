/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WithUser_user = {
    readonly userId: string;
    readonly hashForIntercom: string;
    readonly email: string;
    readonly earlyAccess: boolean;
    readonly unreadNotificationsCount: number;
    readonly buyingDetails: {
        readonly shippingAddress: {
            readonly firstName: string;
            readonly lastName: string;
            readonly addressLine1: string;
            readonly addressLine2: string | null;
            readonly city: string;
            readonly state: string;
            readonly zip: string;
            readonly countryCode: API$CountryCode;
        } | null;
        readonly card: {
            readonly cardId: string;
            readonly brand: string;
            readonly last4: string;
        } | null;
    };
    readonly store: {
        readonly id: string;
        readonly user: {
            readonly id: string;
            readonly userId: string;
        };
        readonly name: string;
        readonly slug: string;
        readonly data: {
            readonly profileImage: string | null;
            readonly introduction: string | null;
            readonly links: {
                readonly twitter: {
                    readonly username: string;
                } | null;
            };
            readonly amazonAssociateIds: ReadonlyArray<{
                readonly country: API$CountryCode;
                readonly associateId: string;
            }>;
            readonly follows: {
                readonly id: string;
                readonly aggregate: {
                    readonly id: string;
                    readonly followsWhereIsSrcCount: number;
                    readonly followsWhereIsTargetCount: number;
                };
                readonly relativeToOther: {
                    readonly isOtherFollowsSrc: boolean;
                    readonly isOtherFollowsTarget: boolean;
                    readonly selfFollowTargetsInOtherFollowTargets: number;
                    readonly selfFollowSrcsInOtherFollowTargets: number;
                } | null;
            };
            readonly payoutPercent: number;
            readonly stripe: {
                readonly __typename: "StripeCreated";
                readonly payoutDelayDays: number;
                readonly payoutDayOfWeek: API$DayOfTheWeek;
                readonly payoutsEnabled: boolean;
                readonly chargesEnabled: boolean;
                readonly requirements: API$StripeRequirements;
                readonly balance: number;
            } | {
                readonly __typename: "StripeNotCreated";
                readonly reason: API$StripeNotCreatedReason;
            } | {
                /*This will never be '%other', but we need some
                value in case none of the concrete values match.*/
                readonly __typename: "%other";
            };
            readonly basicStats: {
                readonly booksSold: number;
                readonly booksRefunded: number;
            };
        } | null;
    } | null;
    readonly settings: {
        readonly email: {
            readonly store: {
                readonly admin: {
                    readonly bookApproved: boolean;
                    readonly bookRejected: boolean;
                    readonly bookSold: boolean;
                };
                readonly social: {
                    readonly likes: boolean;
                    readonly comments: boolean;
                    readonly follows: boolean;
                };
            };
        };
    };
    readonly " $refType": "WithUser_user";
};
export type WithUser_user$data = WithUser_user;
export type WithUser_user$key = {
    readonly " $data"?: WithUser_user$data;
    readonly " $fragmentRefs": FragmentRefs<"WithUser_user">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "WithUser_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hashForIntercom",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "earlyAccess",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unreadNotificationsCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BuyingDetails",
      "kind": "LinkedField",
      "name": "buyingDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Address",
          "kind": "LinkedField",
          "name": "shippingAddress",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "addressLine1",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "addressLine2",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "city",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "state",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "zip",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "countryCode",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PaymentCardDetails",
          "kind": "LinkedField",
          "name": "card",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cardId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "brand",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "last4",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Store",
      "kind": "LinkedField",
      "name": "store",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StoreData",
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "profileImage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "introduction",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "StoreLinks",
              "kind": "LinkedField",
              "name": "links",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StoreLinksTwitter",
                  "kind": "LinkedField",
                  "name": "twitter",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "username",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AmazonAssociateId",
              "kind": "LinkedField",
              "name": "amazonAssociateIds",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "country",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "associateId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "StoreFollows",
              "kind": "LinkedField",
              "name": "follows",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StoreFollowsAggregate",
                  "kind": "LinkedField",
                  "name": "aggregate",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "followsWhereIsSrcCount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "followsWhereIsTargetCount",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "userId",
                      "variableName": "userId"
                    }
                  ],
                  "concreteType": "StoreFollowsRelativeToOther",
                  "kind": "LinkedField",
                  "name": "relativeToOther",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isOtherFollowsSrc",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isOtherFollowsTarget",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "selfFollowTargetsInOtherFollowTargets",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "selfFollowSrcsInOtherFollowTargets",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "payoutPercent",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "stripe",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "payoutDelayDays",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "payoutDayOfWeek",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "payoutsEnabled",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "chargesEnabled",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "requirements",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "balance",
                      "storageKey": null
                    }
                  ],
                  "type": "StripeCreated",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "reason",
                      "storageKey": null
                    }
                  ],
                  "type": "StripeNotCreated",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "StoreBasicStats",
              "kind": "LinkedField",
              "name": "basicStats",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "booksSold",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "booksRefunded",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Settings",
      "kind": "LinkedField",
      "name": "settings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EmailSettings",
          "kind": "LinkedField",
          "name": "email",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EmailSettingsStore",
              "kind": "LinkedField",
              "name": "store",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EmailSettingsStoreAdmin",
                  "kind": "LinkedField",
                  "name": "admin",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "bookApproved",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "bookRejected",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "bookSold",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EmailpreferencesStoreSocial",
                  "kind": "LinkedField",
                  "name": "social",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "likes",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "comments",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "follows",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
(node as any).hash = '8a7b1721a7c008fbfb1636889cc75a36';
export default node;
