import { fGet } from '@indieocean/utils'
import React from 'react'

export function useDepList(deps: any[]): 'new' | 'changed' | 'noChange' {
  const ref = React.useRef<any[]>()
  if (!ref.current) {
    ref.current = deps
    return 'new'
  }
  const prevDeps = fGet(ref.current)
  if (
    prevDeps.length !== deps.length ||
    prevDeps.some((x, i) => x !== deps[i])
  ) {
    ref.current = deps
    return 'changed'
  }
  return 'noChange'
}
