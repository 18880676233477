import { useCallback } from 'react'
import { GraphQLTaggedNode, useMutation, UseMutationConfig } from 'react-relay'
import { MutationParameters } from 'relay-runtime'
import { Config } from '../../../Config'
import { useAssertConst } from '../../Utils/UseAssertConst'
import { useSetGlobalError } from '../GlobalErrorBoundary'
import { useGlobalToasts } from '../WithGlobalToasts'
import { GQLFetchError } from '../WithRelay'

type NewConfig<T extends MutationParameters, Result> = Omit<
  UseMutationConfig<T>,
  'onError' | 'onCompleted'
> & {
  onError?: (error: GQLFetchError) => void | null
  onCompleted: (result: Result) => void | null
}

export function useGQLMutation<
  T extends MutationParameters,
  Result = T['response']
>(mutation: GraphQLTaggedNode, errorHandling: 'soft' | 'hard') {
  const {setGlobalError} = useSetGlobalError()
  const {errorToast} = useGlobalToasts()
  const [commit, isRunning] = useMutation<T>(mutation)

  const newCommit = useCallback(
    (config: NewConfig<T, Result>) => {
      const {onCompleted, onError, ...rest} = config
      const newOnError = (error: Error) => {
        !Config.client.production && console.dir(error)
        if (!(error instanceof GQLFetchError)) {
          throw error
        }
        if (
          error.type === 'UNAUTHENTICATED' ||
          error.type === 'MaintainanceMode'
        ) {
          setGlobalError(error)
        } else {
          onError && onError(error)
          errorHandling === 'soft' ? errorToast(error) : setGlobalError(error)
        }
      }
      const newOnCompleted = (result: any) => onCompleted(result)

      commit({
        ...rest,
        onError: newOnError,
        onCompleted: newOnCompleted,
      })
    },
    // commit is not-const on nextjs fast-refresh (and inside a custom hook). I
    // believe this is only an issue on dev.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setGlobalError, errorHandling, errorToast]
  )
  useAssertConst([setGlobalError, errorToast, errorHandling])
  return [newCommit, isRunning] as const
}
