import { API } from '@indieocean/apidef'
import { noCase } from '@indieocean/utils'
import Image from 'next/image'
import React, { ReactNode } from 'react'
import { ProfileImage } from '../ProfileImage'
import { AppLink, appPath } from '../Tools/AppLink'
import { useBasicStoreLive } from './WithBasicStoreLive'

const _logomarkAspectRatio = 736 / 506
const _logotypeAspectRatio = 2916 / 319

type HeaderSize = 'grid3' | 'grid3-wide' | 'center' | 'split'
export type HeaderSpec =
  | {type: 'store'; size: HeaderSize}
  | {type: 'logo'; size: HeaderSize}
  | {type: 'text'; text: string; size: HeaderSize}
  | null

export const Header = React.memo(({spec}: {spec: HeaderSpec}) => {
  if (!spec) return <></>
  return (
    <_Size size={spec.size}>
      {spec.type === 'store' ? (
        <_StoreHeader />
      ) : spec.type === 'logo' ? (
        <div className=" cursor-pointer">
          <AppLink path={appPath.root}>
            <Image
              src="/logotype.png"
              width={Math.round(25 * _logotypeAspectRatio)}
              height={25}
            />
          </AppLink>
        </div>
      ) : spec.type === 'text' ? (
        <h1 className="font-bold text-3xl">{spec.text}</h1>
      ) : (
        noCase(spec)
      )}
    </_Size>
  )
})
export const _Size = React.memo(
  ({children, size}: {children: ReactNode; size: HeaderSize}) => (
    <div
      className={`sticky top-0 bg-pageBG  z-10
  ${size === 'grid3' || size === 'split' ? 'body-grid3' : 'body-grid3-wide'}`}
      style={{gridArea: 'header'}}
    >
      <div
        className={`body-grid-content-no-vert-pad h-[4.31rem] flex items-center
  ${size === 'center' ? 'justify-center' : ''} 
  ${size === 'split' ? 'md:justify-center' : ''} `}
      >
        {children}
      </div>
    </div>
  )
)

export const _StoreHeader = React.memo(() => {
  const store = useBasicStoreLive()
  const {name, slug} = store

  return (
    <div
      className="grid w-full h-full "
      style={{
        grid: `"image text" auto
              / auto 1fr auto`,
      }}
    >
      <AppLink
        path={API.Path.store(slug).root}
        className={` flex items-center`}
      >
        <ProfileImage
          className="mr-2 border-2 border-inputBorder"
          store={store}
          size={50}
        />
      </AppLink>

      <div className="overflow-hidden h-full flex flex-col justify-center">
        <div className="">
          <h1
            className={`block  text-xl-tight mb-1 font-bold whitespace-nowrap
                overflow-hidden w-full overflow-ellipsis`}
            style={{gridArea: 'name'}}
          >
            {name}
          </h1>
          <h2 className=" text-xs-tight m-0 p-0 ">
            <span className="lighten">on </span>{' '}
            <span className="font-medium">IndieOcean</span>
          </h2>
        </div>
      </div>
    </div>
  )
})
