/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */
import { loadStripe, Stripe } from '@stripe/stripe-js'
import { Config } from '../Config'

let stripePromise: Promise<Stripe | null> | null = null
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(Config.client.connections.stripe.publishableKey)
  }
  return stripePromise
}

export default getStripe
