import {API} from '@indieocean/apidef'
import {FGet, Replace, ReplaceWithFGet} from '@indieocean/utils'
import React, {ReactNode} from 'react'
import {graphql} from 'react-relay'
import {GQL} from '../../../Generated/GQL/TypesFromSDL'
import {createContext} from '../../Utils/CreateContext'

export type BasicStore = GQL.WithBasicStoreLive_Store
export type BasicStoreLive = ReplaceWithFGet<BasicStore, 'data'>

export type BasicStoreRelativeToOther =
  | FGet<BasicStore['data']>['follows']['relativeToOther']
  | undefined

export const isBasicStoreLive = (
  store: BasicStore | null | undefined
): store is BasicStoreLive => (store?.data ?? null) !== null

export const storePath = (store: {slug: string}) => API.Path.store(store.slug)

export const [BasicStoreContext, useBasicStoreLive] =
  createContext<BasicStoreLive>('basicStore')

export const fragment = graphql`
  fragment WithBasicStoreLive_store on Store {
    id
    user {
      id
      userId
    }
    name
    slug
    data {
      profileImage
      introduction
      links {
        twitter {
          username
        }
      }
      amazonAssociateIds {
        country
        associateId
      }
      follows {
        id
        aggregate {
          id
          followsWhereIsSrcCount
          followsWhereIsTargetCount
        }
        relativeToOther(userId: $userId) {
          isOtherFollowsSrc
          isOtherFollowsTarget
          selfFollowTargetsInOtherFollowTargets
          selfFollowSrcsInOtherFollowTargets
        }
      }
    }
  }
`
export const WithBasicStoreLive = React.memo(
  ({store, children}: {store: BasicStoreLive; children: ReactNode}) => {
    return (
      <BasicStoreContext.Provider value={store}>
        {children}
      </BasicStoreContext.Provider>
    )
  }
)
