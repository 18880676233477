import {assert, fGet} from '@indieocean/utils'
import {GraphQLError} from 'graphql'
import {Client, createClient} from 'graphql-ws'
import React, {ReactElement, useState} from 'react'
import {RelayEnvironmentProvider} from 'react-relay'
import {
  Environment,
  FetchFunction,
  GraphQLResponse,
  Network,
  Observable,
  RecordSource,
  RequestParameters,
  Store,
  Variables,
} from 'relay-runtime'
import {Config} from '../../Config'
import {useStrictMemo} from '../Utils/UseStrictMemo'
import {useSetGlobalError} from './GlobalErrorBoundary'
import {useFirebaseUser} from './WithFirebaseUser'

const _getClient = (getIdToken: (() => Promise<string>) | null) => {
  return createClient({
    url: `${Config.client.urls.apiWS}/gqlws`,
    connectionParams: getIdToken
      ? async () => ({authToken: await getIdToken()})
      : undefined,
  })
}
const subscribeRelay =
  (client: Client) => (operation: RequestParameters, variables: Variables) => {
    return Observable.create<GraphQLResponse>(sink => {
      if (!operation.text) {
        return sink.error(new Error('Operation text cannot be empty'))
      }
      return client.subscribe(
        {
          operationName: operation.name,
          query: operation.text,
          variables,
        },
        {
          ...sink,
          //TODO: There was a type error here that I believe is a definition
          //error (I think when updated relay to 12).
          next: sink.next as any,
          error: err => {
            if (err instanceof Error) {
              return sink.error(err)
            }

            if (err instanceof CloseEvent) {
              return sink.error(
                // reason will be available on clean closes
                new Error(
                  `Socket closed with event ${err.code} ${err.reason || ''}`
                )
              )
            }

            return sink.error(
              new Error(
                (err as GraphQLError[]).map(({message}) => message).join(', ')
              )
            )
          },
        }
      )
    })
  }

const serverReportedErrorCodes = [
  'GRAPHQL_PARSE_FAILED',
  'GRAPHQL_VALIDATION_FAILED',
  'PERSISTED_QUERY_NOT_FOUND',
  'PERSISTED_QUERY_NOT_SUPPORTED',
  'BAD_USER_INPUT',
  'UNAUTHENTICATED',
  'FORBIDDEN',
  'INTERNAL_SERVER_ERROR',
  // Custom
  'STATE_MISMATCH',
] as const
type ServerReportedErrorCode = typeof serverReportedErrorCodes[number]

const isServerReportedErrorCode = (
  code: string
): code is ServerReportedErrorCode =>
  serverReportedErrorCodes.indexOf(code as any) !== -1

type GQLFetchErrorType =
  // Fetch reported errors.
  | 'NetworkError'
  | 'ServerError'
  | 'MaintainanceMode'
  // Server reported errors.
  | typeof serverReportedErrorCodes[number]
  // Client reported errors.
  | 'NoStoreToAdminister'
  | 'NotFound'
  | 'Custom'

export class GQLFetchError extends Error {
  type: GQLFetchErrorType

  constructor(type: GQLFetchErrorType, message?: string) {
    super(message ?? type)
    this.type = type
  }
}

export function assertStateMatch(condition: any): asserts condition {
  if (!condition) throwStateMismatch()
}
export function assertAuthorized(condition: any): asserts condition {
  if (!condition) throwForbidden()
}
export function assertFound(condition: any): asserts condition {
  if (!condition) throwNotFound()
}

export function throwForbidden(): never {
  throw new GQLFetchError('FORBIDDEN')
}
export function throwStateMismatch(): never {
  throw new GQLFetchError('STATE_MISMATCH')
}
export function throwNotFound(): never {
  throw new GQLFetchError('NotFound')
}

//NOTE on error handling:
// 1. with useMutation errors thrown here show up in onError callback.
// 2. with useQuery, AFAICT there is NO WAY to surface errors to a react
//    component. Relay swallows thrown errors and ignores errors object in
//    response and will try to render with whatever it has. To deal this with
//    this we always report using setGlobalError().
const fetchRelay =
  (
    getIdToken: (() => Promise<string>) | null,
    setGlobalError: (error: Error) => void
  ): FetchFunction =>
  async (params, variables) => {
    try {
      const headers: any = {'Content-Type': 'application/json'}
      if (getIdToken) headers['Authorization'] = `Bearer ${await getIdToken()}`
      let response: Response
      try {
        response = await fetch(`${Config.client.urls.api}/gql`, {
          method: 'POST',
          headers,
          body: JSON.stringify({query: params.text, variables}),
        })
      } catch (e) {
        throw new GQLFetchError('NetworkError')
      }
      if (response.status === 503) {
        throw new GQLFetchError('MaintainanceMode')
      }
      if (response.status >= 500 && response.status < 600) {
        throw new GQLFetchError('ServerError')
      }

      const {data, errors, ...rest} = await response.json()
      if (errors) {
        const error = fGet(errors[0])
        const code = error.extensions.code
        assert(isServerReportedErrorCode(code))
        throw new GQLFetchError(code, error.message)
      } else {
        return {data, ...rest}
      }
    } catch (e) {
      if (params.operationKind === 'query') {
        setGlobalError(e)
      } else {
        throw e
      }
    }
  }

// Export a singleton instance of Relay Environment configured with our network function:

export const WithRelay = React.memo(({children}: {children: ReactElement}) => {
  const firebaseUser = useFirebaseUser()
  const {setGlobalError} = useSetGlobalError()
  const getIdToken = firebaseUser ? () => firebaseUser.getIdToken() : null
  const fetch = useStrictMemo(() => fetchRelay(getIdToken, setGlobalError), [])
  const subscribe = useStrictMemo(
    () => subscribeRelay(_getClient(getIdToken)),
    []
  )
  const [env] = useState(
    () =>
      new Environment({
        network: Network.create(fetch, subscribe),
        store: new Store(new RecordSource()),
      })
  )

  return (
    <RelayEnvironmentProvider environment={env}>
      {children}
    </RelayEnvironmentProvider>
  )
})
