import { API } from '@indieocean/apidef'
import { b64decode, fGet } from '@indieocean/utils'

export class Config {
  static get server() {
    return {}
  }

  static get client() {
    return {
      urls: {
        app:(path:API.Path)=> `${fGet(process.env.NEXT_PUBLIC_URL_WEBSITE)}${path.toString()}`,
        api: fGet(process.env.NEXT_PUBLIC_URL_API),
        apiWS: fGet(process.env.NEXT_PUBLIC_URL_API_WS),
      },

      google: {
        storage: {
          buckets: {
            data: API.GCS.pathMap(
              (path: string) =>
                `https://storage.googleapis.com/${fGet(
                  process.env.NEXT_PUBLIC_GCS_DATA_BUCKET
                )}/${path}`
            ),
          },
        },
      },
      connections: {
        algolia: {
          applicationId: fGet(process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID),
          searchAPIKey: fGet(process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY),
        },
        googleOAuth: {
          clientId: fGet(process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID),
        },
        sentry: {
          dsn: fGet(process.env.NEXT_PUBLIC_SENTRY_DSN),
        },
        stripe: {
          publishableKey: fGet(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY),
        },
        firebase: JSON.parse(
          b64decode(fGet(process.env.NEXT_PUBLIC_FIREBASE_CONFIG_JSON_BASE64))
        ),
        googleAnalytics: {
          trackingId: fGet(
            process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID
          ),
        },
        intercom: {
          appId: fGet(process.env.NEXT_PUBLIC_INTERCOM_APP_ID),
        },
      },
      production: process.env.NODE_ENV === 'production',
      debug: {},
    }
  }
}
