import { getApp, getApps, initializeApp } from 'firebase/app'
import 'firebase/auth'
import { getAuth } from 'firebase/auth'
import { Config } from '../Config'


if (getApps().length === 0) {
  initializeApp(Config.client.connections.firebase)
}
const firebase = getApp();
const firebaseAuth = getAuth(firebase)
  export { firebaseAuth }

