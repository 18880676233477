import { User } from 'firebase/auth'
import React, {ReactNode, useEffect, useState} from 'react'
import {firebaseAuth} from '../../Remote/Firebase'
import {createContext} from '../Utils/CreateContext'


const [Context, useFirebaseUser] =
  createContext<User | null>('FirebaseAuth')
export {useFirebaseUser}

export const WithFirebaseAuth = React.memo(
  ({children}: {children: ReactNode}) => {
    const [state, setState] =
      useState<User | null | 'checking'>('checking')
    useEffect(() => firebaseAuth.onAuthStateChanged(user => setState(user)), [])
    if (state === 'checking') return <></>

    return (
      <Context.Provider value={state} key={state?.uid ?? ''}>
        {children}
      </Context.Provider>
    )
  }
)
