import { API } from '@indieocean/apidef'
import { FGet, fGet, Replace } from '@indieocean/utils'
import React, { ReactNode, useMemo } from 'react'
import {
  ConnectionHandler,
  graphql,
  useLazyLoadQuery,
  useSubscription
} from 'react-relay'
import { GQL } from '../../Generated/GQL/TypesFromSDL'
import { useUpdateCacheForStore } from '../Pages/Common/UseUpdateCacheForStore'
import { createContext } from '../Utils/CreateContext'
import { GQLFetchError } from './WithRelay'
import { useTargetUser } from './WithTargetUser'
import { WithUserNotificationSubscription } from './__generated__/WithUserNotificationSubscription.graphql'
import { WithUserQuery } from './__generated__/WithUserQuery.graphql'
import { WithUser_user } from './__generated__/WithUser_user.graphql'

const query = graphql`
  query WithUserQuery($userId: String!) {
    user(userId: $userId) {
      id
      ...WithUser_user @relay(mask: false)
    }
    localizationCountry
  }
`

graphql`
  fragment WithUser_user on User {
    userId
    hashForIntercom
    email
    earlyAccess
    unreadNotificationsCount
    buyingDetails {
      ...WithUser_buyingDetails @relay(mask: false)
    }
    store {
      ...WithUser_store @relay(mask: false)
    }
    settings {
      ...WithUser_settings @relay(mask: false)
    }
  }
`

graphql`
  fragment WithUser_store on Store {
    ...WithBasicStoreLive_store @relay(mask: false)
    id
    data {
      payoutPercent
      # Need this for optimistic response for follow toggle.
      stripe {
        ...WithUser_stripe @relay(mask: false)
      }
      basicStats {
        booksSold
        booksRefunded
      }
    }
  }
`

graphql`
  fragment WithUser_stripe on Stripe {
    __typename
    ... on StripeCreated {
      payoutDelayDays
      payoutDayOfWeek
      payoutsEnabled
      chargesEnabled
      requirements
      balance
    }
    ... on StripeNotCreated {
      reason
    }
  }
`

graphql`
  fragment WithUser_buyingDetails on BuyingDetails {
    shippingAddress {
      firstName
      lastName
      addressLine1
      addressLine2
      city
      state
      zip
      countryCode
    }
    card {
      cardId
      brand
      last4
    }
  }
`

graphql`
  fragment WithUser_settings on Settings {
    email {
      store {
        admin {
          bookApproved
          bookRejected
          bookSold
        }
        social {
          likes
          comments
          follows
        }
      }
    }
  }
`

export type User = GQL.WithUser_User
type _Store = FGet<User['store']>
type _LiveStore = Replace<_Store, 'data', FGet<_Store['data']>>
export type UserWithLiveStore = Replace<User, 'store', _LiveStore>

export const isUserWithLiveStore = (user: User): user is UserWithLiveStore =>
  (user.store?.data ?? null) !== null

const [Context, useUser] = createContext<User | null>('User')
export const useFUser = () => fGet(useUser())
export const useFUserWithLiveStore = () => {
  const user = useFUser()
  if (!isUserWithLiveStore(user)) throw new GQLFetchError('NoStoreToAdminister')
  return user
}
export { useUser }

export const WithUser = React.memo(({children}: {children: ReactNode}) => {
  const userId = useTargetUser()
  return userId ? (
    <_LoggedIn userId={userId}>{children}</_LoggedIn>
  ) : (
    <_NotLoggedIn>{children}</_NotLoggedIn>
  )
})

const subscription = graphql`
  subscription WithUserNotificationSubscription($userId: String!) {
    userNotifications(userId: $userId) {
      unreadCount
      edge {
        ...Notifications_edge
      }
    }
  }
`

export const [LocalizationCountryContext, useLocalizationCountry] =
  createContext<API$CountryCode | null>('LocalizationCountry')

const _LoggedIn = React.memo(
  ({userId, children}: {userId: string; children: ReactNode}) => {
    const {user, localizationCountry} = useLazyLoadQuery<WithUserQuery>(query, {
      userId,
    }) as unknown as GQL.WithUserQuery

    useUpdateCacheForStore(user?.store ?? null)
    useSubscription<WithUserNotificationSubscription>(
      useMemo(
        () => ({
          subscription,
          variables: {userId},
          updater: cache => {
            const rootRecord = fGet(cache.getRootField('userNotifications'))
            const unreadCount = rootRecord.getValue('unreadCount')
            const edgeRecord = fGet(rootRecord.getLinkedRecord('edge'))

            const connection = ConnectionHandler.getConnection(
              fGet(cache.get(API.GQLIds.user({userId}))),
              'Notifications_query_notifications'
            )
            if (connection) {
              ConnectionHandler.insertEdgeBefore(connection, edgeRecord)
            }

            const userRecord = fGet(
              cache.get<WithUser_user>(API.GQLIds.user({userId}))
            )
            userRecord.setValue(unreadCount, 'unreadNotificationsCount')
          },
        }),
        [userId]
      )
    )

    return (
      <LocalizationCountryContext.Provider value={localizationCountry}>
        <Context.Provider value={user}>{children}</Context.Provider>
      </LocalizationCountryContext.Provider>
    )
  }
)

const _NotLoggedIn = React.memo(({children}: {children: ReactNode}) => {
  return <Context.Provider value={null}>{children}</Context.Provider>
})
