import React, {useEffect, useState} from 'react'
import {getNotificationSound} from '../../Tools/NotificationSound'
import {User, useUser} from '../../WithUser'
import {NavHorz} from './NavHorz'
import {getNavItems, NavMenuPage} from './NavMenuItemModel'
import {NavVert} from './NavVert'

export type NavProps = {currentPage: NavMenuPage | null; hideHorz?: boolean}
export const Nav = React.memo(({currentPage, hideHorz = false}: NavProps) => {
  const user = useUser()
  const items = getNavItems(user)
  // useNotificationSound(user)
  return (
    <>
      <NavVert currentPage={currentPage} items={items} />
      {!hideHorz && <NavHorz currentPage={currentPage} items={items} />}
    </>
  )
})

function useNotificationSound(user: User | null) {
  const unreadNotificationCount = user?.unreadNotificationsCount ?? 0
  const [localCount, setLocalCount] = useState(unreadNotificationCount)
  useEffect(() => {
    if (unreadNotificationCount > localCount) {
      void getNotificationSound().play()
    }
    setLocalCount(unreadNotificationCount)
  }, [unreadNotificationCount, localCount])
}
