import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Image from 'next/image'
import React from 'react'
import {AppLink} from '../../Tools/AppLink'
import {Badge} from '../../Tools/Badge'
import {NavMenuItemModel, NavMenuPage} from './NavMenuItemModel'

const _logomarkAspectRatio = 736 / 506

export const NavVert = React.memo(
  ({
    currentPage,
    items,
  }: {
    currentPage: NavMenuPage | null
    items: NavMenuItemModel[]
  }) => (
    <div
      className={` bg-pageBG rounded-br-sm fixed top-0 left-0 w-[5rem] pt-2 pb-2 z-40
        hidden vertNav:flex flex-col  items-center `}
      style={{boxShadow: '0px 0px 10px 3px #ccc'}}
    >
      {items.map((item, i) => (
        <_Item key={i} item={item} isCurrent={currentPage === item.name} />
      ))}
    </div>
  )
)

const _Item = React.memo(
  ({item, isCurrent}: {item: NavMenuItemModel; isCurrent: boolean}) => {
    const {path, name, faIcon, nameOnVert, badge} = item
    return (
      <AppLink
        path={path}
        className={`border-inputBorder w-full 
            flex flex-col justify-center items-center pb-4 pt-2
      `}
      >
        <div className="relative pt-2 pl-3 pr-3">
          {faIcon === 'logo' ? (
            <Image
              src="/logomark.png"
              width={Math.round(20 * _logomarkAspectRatio)}
              height={20}
            />
          ) : (
            <FontAwesomeIcon
              className="text-[1.55rem]  text-sectionDarkBG"
              icon={isCurrent ? faIcon.solid : faIcon.light}
            />
          )}

          {badge !== undefined && badge > 0 && <Badge value={badge} />}
        </div>

        {nameOnVert && (
          <h1
            className={`text-[.65rem] whitespace-nowrap ${
              isCurrent ? 'font-medium' : 'lighten-2'
            }`}
          >
            {name}
          </h1>
        )}
      </AppLink>
    )
  }
)
