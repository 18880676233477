/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UserLoginGoogleVerifyIdTokenArgs = {
    idToken: string;
};
export type AppPageVerifyIdTokenMutationVariables = {
    args: UserLoginGoogleVerifyIdTokenArgs;
};
export type AppPageVerifyIdTokenMutationResponse = {
    readonly userLoginGoogleVerifyIdToken: {
        readonly __typename: "UserLoginGoogleVerifyIdTokenSuccess";
        readonly firebaseToken: string;
    } | {
        readonly __typename: "ErrorWithMessage";
        readonly message: string;
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    };
};
export type AppPageVerifyIdTokenMutation = {
    readonly response: AppPageVerifyIdTokenMutationResponse;
    readonly variables: AppPageVerifyIdTokenMutationVariables;
};



/*
mutation AppPageVerifyIdTokenMutation(
  $args: UserLoginGoogleVerifyIdTokenArgs!
) {
  userLoginGoogleVerifyIdToken(args: $args) {
    __typename
    ... on UserLoginGoogleVerifyIdTokenSuccess {
      firebaseToken
    }
    ... on ErrorWithMessage {
      message
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "args"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "args",
        "variableName": "args"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "userLoginGoogleVerifyIdToken",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firebaseToken",
            "storageKey": null
          }
        ],
        "type": "UserLoginGoogleVerifyIdTokenSuccess",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "ErrorWithMessage",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppPageVerifyIdTokenMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppPageVerifyIdTokenMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d88e97c37134afa471bec3086d27d6ae",
    "id": null,
    "metadata": {},
    "name": "AppPageVerifyIdTokenMutation",
    "operationKind": "mutation",
    "text": "mutation AppPageVerifyIdTokenMutation(\n  $args: UserLoginGoogleVerifyIdTokenArgs!\n) {\n  userLoginGoogleVerifyIdToken(args: $args) {\n    __typename\n    ... on UserLoginGoogleVerifyIdTokenSuccess {\n      firebaseToken\n    }\n    ... on ErrorWithMessage {\n      message\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '6d7d18a0324234c86de31c147be194cf';
export default node;
