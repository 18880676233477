import algoliasearch from 'algoliasearch/lite'
import React, { ReactNode, useEffect } from 'react'
import { Config } from '../../Config'
import { createContext } from '../Utils/CreateContext'
import { useAssertConst } from '../Utils/UseAssertConst'
import { useStrictMemo } from '../Utils/UseStrictMemo'

const [Context, useAlgoliaIndex] =
  createContext<ReturnType<typeof _init>['index']>('AlgoliaIndices')

export { useAlgoliaIndex }
export const WithAlgoliaIndices = React.memo(
  ({children}: {children: ReactNode}) => {
    const {client, index} = useStrictMemo(_init, [])
    useEffect(() => {
      return () => void client.clearCache()
    }, [client])
    useAssertConst([client])
    return <Context.Provider value={index}>{children}</Context.Provider>
  }
)

const _init = () => {
  const {applicationId, searchAPIKey} = Config.client.connections.algolia
  const client = algoliasearch(applicationId, searchAPIKey)
  return {
    client,
    index: {
      catalogBooks: client.initIndex('catalogBooks'),
      userBooks: client.initIndex('userBooks'),
      stores: client.initIndex('stores'),
    },
  }
}
