/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WithUser_stripe = {
    readonly __typename: "StripeCreated";
    readonly payoutDelayDays: number;
    readonly payoutDayOfWeek: API$DayOfTheWeek;
    readonly payoutsEnabled: boolean;
    readonly chargesEnabled: boolean;
    readonly requirements: API$StripeRequirements;
    readonly balance: number;
    readonly " $refType": "WithUser_stripe";
} | {
    readonly __typename: "StripeNotCreated";
    readonly reason: API$StripeNotCreatedReason;
    readonly " $refType": "WithUser_stripe";
} | {
    /*This will never be '%other', but we need some
    value in case none of the concrete values match.*/
    readonly __typename: "%other";
    readonly " $refType": "WithUser_stripe";
};
export type WithUser_stripe$data = WithUser_stripe;
export type WithUser_stripe$key = {
    readonly " $data"?: WithUser_stripe$data;
    readonly " $fragmentRefs": FragmentRefs<"WithUser_stripe">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WithUser_stripe",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payoutDelayDays",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payoutDayOfWeek",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payoutsEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "chargesEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requirements",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "balance",
          "storageKey": null
        }
      ],
      "type": "StripeCreated",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reason",
          "storageKey": null
        }
      ],
      "type": "StripeNotCreated",
      "abstractKey": null
    }
  ],
  "type": "Stripe",
  "abstractKey": "__isStripe"
};
(node as any).hash = '105ae618ed4c66b7b9e596c3b22083a0';
export default node;
