import {API} from '@indieocean/apidef'
import Link from 'next/link'
import React, {ReactNode} from 'react'
import {useAppPathFn} from './UseAppPathFn'

// Because importing API is annoying.
export const appPath = API.Path

export const AppLink = React.memo(
  ({
    path,
    children,
    className = '',
    style,
  }: {
    path: API.Path | null | (() => void)
    className?: string
    children: ReactNode
    style?: React.CSSProperties
  }) => {
    const pathFn = useAppPathFn()
    return path && typeof path !== 'function' ? (
      <Link href={pathFn(path)}>
        <a className={className} style={style}>
          {children}
        </a>
      </Link>
    ) : (
      <a
        className={`${className} ${path ? ' cursor-pointer' : ''}`}
        style={style}
        onClick={path ?? undefined}
      >
        {children}
      </a>
    )
  }
)
