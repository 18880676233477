/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WithUser_settings = {
    readonly email: {
        readonly store: {
            readonly admin: {
                readonly bookApproved: boolean;
                readonly bookRejected: boolean;
                readonly bookSold: boolean;
            };
            readonly social: {
                readonly likes: boolean;
                readonly comments: boolean;
                readonly follows: boolean;
            };
        };
    };
    readonly " $refType": "WithUser_settings";
};
export type WithUser_settings$data = WithUser_settings;
export type WithUser_settings$key = {
    readonly " $data"?: WithUser_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"WithUser_settings">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WithUser_settings",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EmailSettings",
      "kind": "LinkedField",
      "name": "email",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EmailSettingsStore",
          "kind": "LinkedField",
          "name": "store",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EmailSettingsStoreAdmin",
              "kind": "LinkedField",
              "name": "admin",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "bookApproved",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "bookRejected",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "bookSold",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "EmailpreferencesStoreSocial",
              "kind": "LinkedField",
              "name": "social",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "likes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "comments",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "follows",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Settings",
  "abstractKey": null
};
(node as any).hash = 'e51c901d2657583aaa95f366a0facd6b';
export default node;
