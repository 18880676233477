import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from 'next/image'
import React from 'react'
import { AppLink } from '../../Tools/AppLink'
import { Badge } from '../../Tools/Badge'
import { NavMenuItemModel, NavMenuPage } from './NavMenuItemModel'

const _logomarkAspectRatio = 736 / 506

export const NavHorz = React.memo(
  ({
    currentPage,
    items,
  }: {
    currentPage: NavMenuPage | null
    items: NavMenuItemModel[]
  }) => (
    <div
      className={`bg-pageBG sticky bottom-0 grid vertNav:hidden z-10`}
      style={{
        grid: `4.92rem / repeat(${items.length}, 1fr)`,
        gridArea: 'horzNav',
      }}
    >
      {items.map((item, i) => (
        <_Item key={i} item={item} isCurrent={currentPage === item.name} />
      ))}
    </div>
  )
)

const _Item = React.memo(
  ({item, isCurrent}: {item: NavMenuItemModel; isCurrent: boolean}) => {
    const {path, name, faIcon, badge} = item
    return (
      <AppLink path={path}
          className={`border-inputBorder  pb-1 mb-3 
          flex flex-col justify-center items-center 
          `}
        >
          <div className="relative ">
            {faIcon === 'logo' ? (
              <Image
                src="/logomark.png"
                width={Math.round(20 * _logomarkAspectRatio)}
                height={20}
              />
            ) : (
              <FontAwesomeIcon
                className="text-[1.55rem] text-sectionDarkBG"
                icon={isCurrent ? faIcon.solid : faIcon.light}
              />
            )}
            {badge !== undefined && badge > 0 && <Badge value={badge} />}
          </div>
          <h1
            className={`text-[.70rem] whitespace-nowrap ${
              isCurrent ? 'font-medium' : 'lighten-2'
            }`}
          >
            {name}
          </h1>
      </AppLink>
    )
  }
)
