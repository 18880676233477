import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {
  faBell as faBellLight,
  faCompass as faCompassLight,
  faEllipsisVAlt as faEllipsisVLight,
  faHomeAlt as faHomeAltLight,
  faStoreAlt as faStoreAltLight,
  faUser as faUserLight,
} from '@fortawesome/pro-light-svg-icons'
import {
  faBell as faBellSolid,
  faCompass as faCompassSolid,
  faEllipsisV as faEllipsisVSolid,
  faHomeAlt as faHomeAltSolid,
  faStoreAlt as faStoreAltSolid,
  faUser as faUserSolid,
} from '@fortawesome/pro-solid-svg-icons'
import {API} from '@indieocean/apidef'
import {User} from '../../WithUser'

const faCompass = {light: faCompassLight, solid: faCompassSolid}
const faUser = {light: faUserLight, solid: faUserSolid}
const faHomeAlt = {light: faHomeAltLight, solid: faHomeAltSolid}
const faStoreAlt = {light: faStoreAltLight, solid: faStoreAltSolid}
const faBell = {light: faBellLight, solid: faBellSolid}
const faEllipsisV = {light: faEllipsisVLight, solid: faEllipsisVSolid}

export type NavMenuPage =
  | 'Explore'
  | 'Login'
  | 'Account'
  | 'Home'
  | 'Your Store'
  | 'Create a Store'
  | 'Notifications'
  | 'About'

export type NavMenuItemModel = {
  name: NavMenuPage
  path: API.Path
  faIcon: {light: IconProp; solid: IconProp} | 'logo'
  nameOnVert: boolean
  badge?: number
}

export const getNavItems = (user: User | null): NavMenuItemModel[] => {
  if (!user) {
    return [
      {
        name: 'Create a Store',
        path: API.Path.getStarted(),
        faIcon: faStoreAlt,
        nameOnVert: true,
      },
      {
        name: 'Explore',
        path: API.Path.explore,
        faIcon: faCompass,
        nameOnVert: true,
      },
      {name: 'Login', path: API.Path.login(), faIcon: faUser, nameOnVert: true},
      {name: 'About', path: API.Path.root, faIcon: 'logo', nameOnVert: false},
    ]
  } else if (!user.store?.data) {
    return [
      {
        name: 'Create a Store',
        path: API.Path.getStarted(),
        faIcon: faStoreAlt,
        nameOnVert: true,
      },
      {
        name: 'Explore',
        path: API.Path.explore,
        faIcon: faCompass,
        nameOnVert: true,
      },
      {
        name: 'Account',
        path: API.Path.account.root,
        faIcon: faUser,
        nameOnVert: false,
      },
      {name: 'About', path: API.Path.root, faIcon: 'logo', nameOnVert: false},
    ]
  } else {
    return [
      {name: 'Home', path: API.Path.home, faIcon: faHomeAlt, nameOnVert: false},
      {
        name: 'Your Store',
        path: API.Path.store(user.store.slug).root,
        faIcon: faStoreAlt,
        nameOnVert: true,
      },
      {
        name: 'Explore',
        path: API.Path.explore,
        faIcon: faCompass,
        nameOnVert: true,
      },
      {
        name: 'Notifications',
        path: API.Path.notifications,
        faIcon: faBell,
        nameOnVert: false,
        badge:
          user.unreadNotificationsCount > 0
            ? user.unreadNotificationsCount
            : undefined,
      },
      {
        name: 'Account',
        path: API.Path.account.root,
        faIcon: faUser,
        nameOnVert: false,
      },
    ]
  }
}
