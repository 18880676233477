import {noCase} from '@indieocean/utils'
import {CircularProgress} from '@material-ui/core'
import React, {RefObject} from 'react'

export const CenteredCircleProgress = React.memo(
  React.forwardRef(
    (
      {
        size = 'base',
        bg = 'light',
      }: {
        ref?: RefObject<unknown>
        size?: 'sm' | 'md' | 'base'
        bg?: 'dark' | 'light'
      },
      ref
    ) => {
      const pxSize =
        size === 'sm'
          ? 20
          : size === 'md'
          ? 25
          : size === 'base'
          ? 30
          : noCase(size)
      return (
        <CircularProgress
          ref={ref}
          classes={{
            root:
              bg === 'dark'
                ? 'text=iconDarkFG'
                : bg === 'light'
                ? 'text-iconLightFG'
                : noCase(bg),
          }}
          color="inherit"
          size={pxSize}
          style={{
            left: '50%',
            top: '50%',
            position: 'absolute',
            marginTop: -pxSize / 2,
            marginLeft: -pxSize / 2,
          }}
        />
      )
    }
  )
)
