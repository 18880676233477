/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type WithTargetUserQueryVariables = {
    slug: string;
};
export type WithTargetUserQueryResponse = {
    readonly store: {
        readonly user: {
            readonly userId: string;
        };
    } | null;
};
export type WithTargetUserQuery = {
    readonly response: WithTargetUserQueryResponse;
    readonly variables: WithTargetUserQueryVariables;
};



/*
query WithTargetUserQuery(
  $slug: String!
) {
  store(slug: $slug) {
    user {
      userId
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WithTargetUserQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WithTargetUserQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Store",
        "kind": "LinkedField",
        "name": "store",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "738c5d17e67b75d9778ec19add8c84ad",
    "id": null,
    "metadata": {},
    "name": "WithTargetUserQuery",
    "operationKind": "query",
    "text": "query WithTargetUserQuery(\n  $slug: String!\n) {\n  store(slug: $slug) {\n    user {\n      userId\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a1d283a2599c35a3da3f68d71cb8fa74';
export default node;
